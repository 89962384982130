import loadable from "@loadable/component"
import useDeleteHook from "../../customHooks/useDeleteHook";
import APIS from "../../contants/EndPoints";


const IOSComponent = loadable(() => import("../../components/IOS/ios"));

const ios = () => {
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["ios"] })

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.IOS}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <IOSComponent
      handleDelete={handleDelete} />
  )
}

export default ios