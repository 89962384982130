import loadable from "@loadable/component"
import useDeleteHook from "../../customHooks/useDeleteHook";
import APIS from "../../contants/EndPoints";


const WindowsComponent = loadable(() => import("../../components/Windows/windows"));
const windows = () => {
    const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["windows"] })

    const handleDelete = async (setOpenPopup: any, pk: string) => {
        const url = `${APIS.WINDOWS}${pk}/`
        try {
            const res = await deleteMutateAsync({ url })
            console.log(res, "ii")
            setOpenPopup(false)
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <WindowsComponent
            handleDelete={handleDelete}
        />
    )
}

export default windows