const APIS = {
  // Pages
  WEB: `/web/`,
  ANDROID:`/gcm/`,
  IOS:`/apns/`,
  WINDOWS:`/wns/`
  //THEMES

};

export default APIS;
