import loadable from "@loadable/component"
import useDeleteHook from "../../customHooks/useDeleteHook";
import APIS from "../../contants/EndPoints";


const WebComponent = loadable(() => import("../../components/Web/web"));

const web = () => {
    const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["web"] })

    const handleDelete = async (setOpenPopup: any, pk: string) => {
        const url = `${APIS.WEB}${pk}/`
        try {
            const res = await deleteMutateAsync({ url })
            console.log(res, "ii")
            setOpenPopup(false)
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <WebComponent
            handleDelete={handleDelete} />
    )
}

export default web